<template>
    <div class="cal-sign">
        <van-cell
        icon="label-o"
        >
        <div slot="title">
            <div class="flex-v">
                <div class="flextitle">
                    {{data.name}}
                </div>
                <div class="r-num">
                    <el-tag effect="dark" type="danger" size="mini" 
                        closable @close="del(current)">第{{current+1}}个</el-tag>
                </div>
            </div>
        </div>
        <template #icon>
            <div style="vertical-align:middle;margin-right:5px">
                <van-icon name="../assets/images/yidong.png" @click="UpOrDown"/>
            </div>
        </template>
        <div slot="label">
            <van-field label="名称" v-if="data.spread" type="text"
        v-model="data.name" placeholder="请输入字段名称" />
        </div>
        <div class="cell-title" slot="right-icon">
            <van-icon @click="close(current)" :name="data.spread?'arrow-up':'arrow-down'" />
        </div>
        </van-cell>
        <van-radio-group v-model="data.rules.cal_type" v-if="data.spread">
        <van-cell-group title="统计类型">
            <van-cell title="列表数量" clickable @click="data.rules.cal_type = 'num'">
                <van-radio name="num" slot="right-icon"/>
            </van-cell>
            <van-cell title="子组件数据" clickable @click="data.rules.cal_type = 'children'">
                <van-radio name="children" slot="right-icon"/>
            </van-cell>
        </van-cell-group>
        </van-radio-group>
        <!-- children自组件数据 -->
        <van-cell-group title="子组件数据" v-if="data.rules.cal_type=='children'&&data.spread||data.rules.condition.delimiter&&data.spread">
            <van-cell title="选择子组件" clickable @click="show=true">
                <div slot="right-icon" class="slot">{{data.index3!=undefined?data.calList[data.index3].title:''}}</div>
            </van-cell>
        </van-cell-group>
        <!-- 分隔符 -->
        <van-radio-group v-model="data.rules.condition.hasRemark" v-if="data.spread&&data.rules.cal_type == 'num'">
        <van-cell-group title="分隔符" v-if="data.rules.condition.hasRemark" >
                <van-field type="text"  label="分隔符号" 
                v-model="data.rules.condition.delimiter" @change="delimiterChange"
                 placeholder="可输入‘，|‘等分隔符" />
        </van-cell-group>
        </van-radio-group>
        <van-cell-group title="记分" v-if="data.rules.cal_type=='num'&&data.spread" >
            <van-cell
                clickable
                title="开启记分？"
                >
                 <van-checkbox slot="right-icon" v-model="data.rules.isCal" :name="data.name" ref="checkboxes" />
                <van-field type="number"  label="记分" v-if="data.rules.isCal" 
                slot="label" v-model="data.rules.score" 
                 placeholder="以下条件外记分" />
            </van-cell>
            <van-cell title="少于多少条数"  v-if="data.rules.isCal">
                 <van-checkbox slot="right-icon" v-model="data.rules.condition.mini.disabled" 
                 ref="checkboxes1" />
                 <van-field type="number"  label="最少条数" v-if="data.rules.condition.mini.disabled" slot="label" v-model="data.rules.condition.mini.data" 
                 placeholder="最少*条（少于*条记分）" />
                 <van-field type="number" label="记分" v-if="data.rules.condition.mini.disabled" slot="label" v-model="data.rules.condition.mini.score" 
                 placeholder="少于以上条数得分" />
            </van-cell>
            <van-cell title="多于多少条数"  v-if="data.rules.isCal">
                 <van-checkbox slot="right-icon" v-model="data.rules.condition.max.disabled" 
                 ref="checkboxes2" />
                 <van-field type="number"  label="多于*条数" v-if="data.rules.condition.max.disabled" slot="label" v-model="data.rules.condition.max.data" 
                 placeholder="多于*条（多于*条记分）" />
                 <van-field type="number" label="记分" v-if="data.rules.condition.max.disabled" slot="label" v-model="data.rules.condition.max.score" 
                 placeholder="多于以上条数得分" />
            </van-cell>
        </van-cell-group>
        <!-- 上拉菜单 -->
        <van-dialog
        v-model="show"
        title=""
        >
            <van-picker :columns="columns" @change="onChange" />
        </van-dialog>
        <!-- 记分 -->
        <van-cell-group title="记分" v-if="data.rules.cal_type=='children'&&data.spread&&data.index2" >
            <van-cell
                clickable
                title="开启记分？"
                >
                 <van-checkbox slot="right-icon" v-model="data.rules.isCal" :name="data.name" ref="checkboxes" />
            </van-cell>
            <div v-if="data.rules.isCal">
                <van-cell
                clickable
                v-for="(item,index) in data.itemlist"
                :key="index"
                :title="item.name"
                >
                    <van-field type="number"  label="记分" 
                    slot="label" v-model="item.score" 
                    @change="changeSt"
                    placeholder="记分" />
                </van-cell>
            </div>
            
        </van-cell-group>
    </div>
</template>
<script>
import Vue from 'vue';
import {Checkbox,Cell,CellGroup,RadioGroup,Radio,Field,Picker,Dialog} from 'vant';
Vue.use(Checkbox).use(Cell).use(CellGroup).use(RadioGroup).use(Radio).use(Field).use(Picker).use(Dialog)
export default {
    name:"calList",
    props:{
        data:{
            type:Object
        },
        current:{
            type:Number
        }
    },
    data(){
        return{
            show:false,
            columns:[]
        }
    },
    watch:{
        data:{
            handler(){
                this.init()
            },
            deep:true,
            immediate:true
        }
    },
    methods:{
        //选择上下移动
        UpOrDown(e){
           // console.log(e)
            this.$emit("upodown",{data:this.current,position:{x:e.clientX,y:e.clientY}})
        },
        delimiterChange(e){
            if(e){
                this.$message('请选择分割的子组件数据');
            }
        },
        changeSt(){
            this.$forceUpdate()
        },
        onChange(e,n){
            console.log(this.data.calList[n.value].menu)
            this.data.index3 = n.value
            if(this.data.calList[n.value]&&this.data.calList[n.value].menu=="checkBox"){
                //选择了自组件为选择项
                let list=[]
                this.data.calList[n.value].setting.list.forEach(item=>{
                    list.push({
                        name:item.name,
                        checked:false,
                        score:0
                    })
                })
                this.data.itemlist=list
            }else if(this.data.calList[n.value]&&this.data.calList[n.value].menu=="radio"){
                let list=[]
                this.data.calList[n.value].setting.list.forEach(item=>{
                    console.log(item)
                    list.push({
                        name:item,
                        checked:false,
                        score:0
                    })
                })
                this.data.itemlist=list
            }else{
                this.data.itemlist=[]
            }
            //console.log(this.data.itemlist)
        },
        init(){
            //console.log(this.data.type)
            if(this.data.type=="ListAdd"){
                let columns=[]
                this.data.calList.forEach((e,index) => {
                    columns.push({text:e.title,value:index})
                });
                this.columns = columns
                console.log(this.data.index2)
            }
        },
        //点击
        toggle(){
           this.data.rules.isCal = !this.data.rules.isCal
        },
        del(index){
            this.$emit("del",{index})
        },
        close(index){
            this.$emit("close",{index})
        }
    }
}
</script>
<style lang="less" scoped>
@import "../../assets/style/cal.less";
</style>