<template>
    <div class="radio-box" @click="click" :class="hover?'hover':''">
        <van-cell 
            :title="item.title" >
            <div class="slot-icon" slot="right-icon" :title="item.menu=='radio'?'单选':'多选'">
                <van-icon :name="item.icon"></van-icon>
            </div>
        </van-cell>
    </div>
</template>
<script>
import Vue from 'vue';
import { Cell ,Icon} from 'vant';
Vue.use(Cell);
Vue.use(Icon)
export default {
    name:"radio",
    props:{
        item:{
            type:Object
        },
        hover:{
            type:Boolean
        }
    },
    data(){
        return{

        }
    },
    methods:{
        click(){
            this.$emit("click")
        }
    }
}
</script>
<style lang="less" scoped>
.hover{
    border:2px #fff solid;
}
.hover:hover{
    border:2px #39F solid;
}
</style>