<template>
    <div class="num-box" :class="hover?'hover':''">
            <van-cell 
            :title="item.title" >
            <div class="slot-icon" slot="right-icon">
                <van-stepper v-model="item.data" disabled/>
            </div>
        </van-cell>
    </div>
</template>
<script>
export default {
    name:"number",
    props:{
        item:{
            type:Object
        },
        hover:{
            type:Boolean
        }
    },
    data(){
        return{

        }
    }
}
</script>
<style lang="less" scoped>
.hover{
    border:2px #fff solid;
}
.hover:hover{
    border:2px #39F solid;
}
</style>