<template>
    <div class="test-box">
        <div class="left" v-loading="loading">
            <Phone>
            <!-- 页面导航 -->
            <PageNav :ntitle="title" :length="list.length"
                     :page="page" @next="next"/>
            <div class="content" id="listBox" ref="obtain">
                <div class="item-box" v-if="list[page]">
                    <div v-for="(item,index) in list[page].list" :key="index" >
                        <!-- 签到 -->
                        <div class="item " v-if="item.menu=='sign'" @click="addrow(item,index)">
                            <van-cell 
                            :title="item.title" :value="item.explaination" >
                            <div class="slot-icon" slot="icon">
                                <van-tag plain type="danger">{{index+1}}</van-tag>
                            </div>
                            </van-cell>
                        </div>
                        <!-- 列表 -->
                        <div class="item" v-else-if="item.menu=='ListAdd'" @click="addrow(item,index)">
                            <van-cell 
                            :title="item.title" :value="item.explaination" >
                            <div class="slot-icon" slot="icon">
                                <van-tag plain type="danger">{{index+1}}</van-tag>
                            </div>
                            </van-cell>
                            <List hover :item="item"/> 
                        </div>
                        <!-- 流程 -->
                        <div class="item" v-else-if="item.menu=='nav'">
                            <van-cell
                            :title="item.title" :value="item.explaination" >
                            <div class="slot-icon" slot="icon">
                                <van-tag plain type="danger">{{index+1}}</van-tag>
                            </div>
                            </van-cell>
                            <div class="nav-box" v-if="item.setting.list">
                                <div class="v-nav" v-for="(item1,index1) in item.setting.list" :key="index1">
                                    <van-cell  icon="label-o" :clickable="item1.type=='device'" @click="addDeviceRow(item1,index,index1)"
                                     :title="item1.title" :value="item1.explaination" >
                                     <div v-if="item1.type=='device'" slot="right-icon" >
                                        <i class="el-icon-circle-plus-outline" />
                                    </div>
                                    </van-cell>
                                     <div class="f-i" v-if="item1.pages">
                                         <div class="pages-item" v-for="(item2,index2) in item1.pages" :key="index2">
                                             <List @click="addrow(item2,index,index1,index2)" hover v-if="item2.menu=='ListAdd'" :item="item2"/> 
                                             <upload hover @click="addrow(item2,index,index1,index2)" v-else-if="item2.menu=='upload'" :item="item2"/>
                                             <radio hover @click="addrow(item2,index,index1,index2)" v-else-if="item2.menu=='radio'||item2.menu=='checkBox'" :item="item2"/>
                                            <!-- <van-cell  @click="addrow(item2,index,index1,index2)" v-else-if="item2.menu=='text'"
                                                :title="item2.title" :value="item2.explaination" >
                                                </van-cell> -->
                                            <Textval @click="addrow(item2,index,index1,index2)" v-else-if="item2.menu=='text'" hover :item="item2" />
                                            <number v-else-if="item2.menu=='input'" />
                                         </div>
                                     </div>
                                </div>
                            </div>
                        </div>
                        <!-- upload -->
                        <div class="item" v-else-if="item.menu=='upload'" @click="addrow(item,index)">
                            <van-cell 
                            :title="item.title" :value="item.explaination" >
                            <div class="slot-icon" slot="icon">
                                <van-tag plain type="danger">{{index+1}}</van-tag>
                            </div>
                            </van-cell>
                            <upload hover :item="item"/>
                        </div>
                        <!-- remark -->
                        <div class="item" v-else-if="item.menu=='text'" @click="addrow(item,index)">
                            <Textval hover :item="item" />
                        </div>
                        <!-- radio -->
                        <div class="item" v-else-if="item.menu=='radio'||item.menu=='checkBox'" @click="addrow(item,index)">
                            <radio hover :item="item"/>
                        </div>
                        <!-- number -->
                        <div class="item" v-else-if="item.menu=='input'" @click="addrow(item,index)">
                            <number hover :item="item"/>
                        </div>
                    </div>
                </div>
                
            </div>
        </Phone>
        </div>
        <div class="right" v-loading="loading">
            <Phone>
            <!-- 页面导航 -->
            <PageNav ntitle="辅助统计列表设置" :page="0" :length="1">
                <i style="font-size:20px" @click="toSpread" :class="!spread?'el-icon-d-caret':'el-icon-caret-bottom'" :title="!spread?'展开':'收缩'"></i>
            </PageNav>
            <div class="content" ref="content">
            <div class="l-con">
                <el-empty v-if="testList.length<=0" description="请点击左侧phone中的内容添加"/>
                <div class="cal-item" v-for="(item,index) in testList" :key="index">
                    <!-- <el-badge :value="index" class="item"/> -->
                    <div  class="line-box-item" ></div>
                    <calSign @upodown="upodown" v-if="item.type=='sign'" @del="del" :current="index" :data="item" @close="close"/>
                    <calUpload @upodown="upodown" v-if="item.type=='upload'" @del="del" :current="index" :data="item"  @close="close"/>
                    <calCheckBox @upodown="upodown" v-if="item.type=='checkBox'||item.type=='radio'" @del="del" :current="index" :data="item"  @close="close"/>
                    <calText @upodown="upodown" v-if="item.type=='text'" @del="del" :current="index" :data="item"  @close="close"/>
                    <calList @upodown="upodown" v-if="item.type=='ListAdd'"  @del="del" :current="index" :data="item" @close="close"/>
                    <calDevice @upodown="upodown" v-if="item.type=='device'"  @del="del" :current="index" :data="item" @close="close"/>
                </div>
                <Drag opacity="0.9" :startHeight="48" background="#FF9912" :num="testList.length" :index="index" :current="current" :time="time"
                 v-if="show" @mouseUp="mouseUp" @change="change" border>
                    <van-cell icon="../../assets/images/yidong.png" :title="testList[current].name+'-鼠标拖拽移动'"/>
                </Drag>
                <!-- tianjia -->
                <div class="btn-box" v-if="testList.length>0">
                    <el-button type="primary" plain @click="save">保存</el-button>
                </div>
            </div>
            </div>
            </Phone>
        </div>
    </div>
</template>
<script>
import Drag from '@/components/Drag'
import Vue from 'vue';
import Phone from '@/components/Phone.vue';
import upload from '@/components/test/upload'
import List from '@/components/test/list'
import { Cell,Tag,Icon,Stepper,Checkbox} from 'vant';
import radio from '@/components/test/radio'
import PageNav from '@/components/widget/PageNav.vue'
import number from '@/components/test/number'
import calSign from '@/components/test/calSign'
import calUpload from '@/components/test/calUpload'
import calCheckBox from '@/components/test/calCheckBox'
import calText from '@/components/test/calText'
import Textval from '@/components/test/text'
import calList from '@/components/test/calList'
import calDevice from '@/components/test/calDevice'
Vue.use(Stepper);
Vue.use(Tag);
Vue.use(Cell);
Vue.use(Icon)
Vue.use(Checkbox)
export default {
    components:{
        calDevice,
        Phone,
        upload,
        List,
        radio,
        PageNav,
        number,
        calSign,
        calUpload,
        calCheckBox,
        Textval,
        calList,
        calText,
        Drag
    },
    data(){
        return{
            time:new Date().toString(),
            index:0,
            current:0,
            show:false,//显示drag
            spread:true,//展开组件
            id:'',
            page:0,
            title:"",
            list:[],
            testList:[],//考核列表
            data:{},
            loading:false
        }
    },
    watch:{
        '$route.query.id':{
            handler(){
                //console.log('gengxin---')
                this.init()
            },
            immediate:true,
            deep:true
        }
    },
    methods:{
        //滑块位置改变凸显出要替换的dom
        change(e){
            //let {targetIndex} = e 
            this.targetIndex = e.targetIndex
        },
        mouseUp(e){
            this.show = false
            let {current,targetIndex} = e
            console.log(current+'移动到'+targetIndex)
            this.setSort(current,targetIndex,this.testList)
        },
        //移动位置
        setSort(index,targetIndex,arr){
            console.log(index,targetIndex)
            if(targetIndex<index){
                //上移动
                arr.splice(targetIndex,0,arr[index])
                arr.splice(index+1,1)
                //arr.splice(targetIndex<index?index+1:index,1)
            }else{
                const m = arr[index]
                arr.splice(index,1)
                arr.splice(targetIndex,0,m)
            }
            //console.log(JSON.stringify(arr))
            return arr
        },
        upodown(e){
            this.show = true 
            this.current = e.data
            this.index = e.data+1
            this.time = new Date().toString()
        },
        //收缩展开组件
        toSpread(){
            this.spread = !this.spread 
            let that=this
            console.log(this.testList)
            this.testList.forEach(item=>{
                item.spread = that.spread
            })
        },
        save(){
            //console.log(this.testList)
            //console.log(this.data)
            if(this.testList.length>=1){
                //保存数据
                this.loading = true
                let params={
                    method:"SaveAuxiliaryStatistics",
                    name:this.title,
                    content:JSON.stringify(this.testList)
                }
                this.$ajax.post(this.API.api,params).then(res=>{
                    this.loading = false
                    console.log(res)
                 }).catch(err=>{
                     console.log(err)
                    this.loading=false
                    this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
                })
            }else{
                this.$toast("没有添加统计列表！")
            }
        },
        init(){
             let data = JSON.parse(sessionStorage.getItem("testList"))
            this.id = data.id 
            this.title = data.name
            //console.log(this.id,this.title)
            this.list = JSON.parse(data.content)
            this.getAux()
            //console.log(this.list)
        },
        //获取之前设置的辅助统计列表
        getAux(){
            let params={
                    method:"getAux",
                    name:this.title
                }
                this.loading=true
                //console.log(params)
                this.$ajax.post(this.API.api,params).then(res=>{
                    this.loading = false
                    //console.log(res)
                    let {data} = res
                    if(data.length>=1&&data[0].content){
                        this.testList=JSON.parse(data[0].content)
                    }else{
                        this.testList=[]
                    }
                 }).catch(err=>{
                     console.log(err)
                    this.loading=false
                    this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
                })
        },
        //添加设备检查数据
        addDeviceRow(item,index,index1){
            console.log(item,index,index1)
            let row={
                title:item.title,
                menu:"device"
            }
            this.addrow(row,index,index1)
        },
        //添加
        addrow(row,index,index1,index2){
            let data={},itemlist=[],list=[]
            let hasRemark = false
            //console.log(row,'---')
            switch(row.menu){
                case "device":
                    //设备检查
                    data = {
                        name:row.title,
                        spread:true,//是否展开
                        type:row.menu,//统计类型
                        page:this.page,//当前页
                        current:index,//当前组件
                        index1,//当前组件内的第几个组件
                        index2,//当前组件内的组件内的第几个组件
                        rules:{//统计内容方式
                           cal_type:"", //统计类型:deviceNum=>检查设备数量,deviceData=>检查设备虫害数据，
                           isCal:false,//是否记分
                           score:0,//得分，满足以下条件外
                           children:'',
                           childrenItem:'',
                           getDeviceNum:false,//获取设备信息
                           getDeviceImgsNum:false,//获取设备图片数量
                           getDeviceImgs:false,//获取图片
                           condition:{
                               mini:{
                                   data:0,
                                   hint:"",
                                   disabled:false,//是否生效
                                   score:0
                               },
                               max:{
                                   data:5,
                                   hint:"",
                                   disabled:false,//是否生效
                                   score:0
                               }
                           },
                        }
                    }
                    break;
                case "sign":
                    //拍照签到
                    data = {
                        name:row.title,
                        spread:true,//是否展开
                        type:row.menu,//统计类型
                        page:this.page,//当前页
                        current:index,//当前组件
                        index1,//当前组件内的第几个组件
                        index2,//当前组件内的组件内的第几个组件
                        rules:{//统计内容方式
                           cal_type:"", //统计类型:usetime=>用时，start=>开始时间,end=>结束时间,start_address=>开始打卡地址,end_address=>结束打卡地址,weather=>天气
                           isCal:false,//是否记分
                           score:0,//得分，满足以下条件外
                           condition:{
                               mini:{
                                   data:0,
                                   hint:"",
                                   disabled:false,//是否生效
                                   score:0
                               },
                               max:{
                                   data:60,
                                   hint:"",
                                   disabled:false,//是否生效
                                   score:0
                               }
                           },
                        }
                    }
                    break;
                case "upload":
                    //拍照签到
                    data = {
                        name:row.title,
                        spread:true,//是否展开
                        type:row.menu,
                        page:this.page,
                        current:index,
                        index1,
                        index2,
                        rules:{//统计内容方式
                           cal_type:"num", //统计类型:num=>图片数量
                           isCal:false,//是否记分
                           score:0,//得分，以下条件外
                           condition:{
                               mini:{
                                   data:0,
                                   hint:"",
                                   disabled:false,//是否生效
                                   score:0
                               }
                           },
                        }
                    }
                    break;
                case "checkBox":
                    //多选
                    itemlist=row.setting.list
                    itemlist.forEach(item=>{
                        item.score=0
                    })
                    data = {
                        name:row.title,
                        spread:true,//是否展开
                        type:row.menu,
                        page:this.page,
                        current:index,
                        itemlist,
                        index1,
                        index2,
                        rules:{//统计内容方式
                           cal_type:"content", //统计类型:content=>内容,
                           isCal:false,//是否记分
                           score:0,//得分，以下条件外
                           condition:{
                           },
                        }
                    }
                    break;
                case "radio":
                    list=row.setting.list
                    list.forEach(item=>{
                        itemlist.push({
                            name:item,
                            score:0,
                            checked:false
                        })
                    })
                    data = {
                        name:row.title,
                        spread:true,//是否展开
                        type:row.menu,
                        page:this.page,
                        current:index,
                        itemlist,
                        index1,
                        index2,
                        rules:{//统计内容方式
                           cal_type:"content", //统计类型:content=>内容,
                           isCal:false,//是否记分
                           score:0,//得分，以下条件外
                           condition:{
                           },
                        }
                    }
                    break;
                case "text":
                    //备注
                    data = {
                        name:row.title,
                        spread:true,//是否展开
                        type:row.menu,
                        page:this.page,
                        current:index,
                        index1,
                        index2,
                        rules:{//统计内容方式
                           cal_type:"content", //统计类型:content=>内容,
                           isCal:false,//是否记分
                           score:0,//得分，以下条件外
                           condition:{
                           },
                        }
                    }
                    break;
                case "ListAdd":
                    //console.log(row)
                    //设置自组件统计参数
                    if(row.setting&&row.setting.list){
                        let children=[],title=''
                        row.setting.list.forEach(item => {
                            if(item.menu=="text"){
                                //有文字备注，方便分隔符分类
                                hasRemark = true
                            }
                            switch(item.menu){
                                case "upload":
                                    title="上传图片"
                                    break;
                                case "text":
                                    title="备注";
                                    break;
                                case "radio":
                                    title = "单选";
                                    break;
                                case "checkBox":
                                    title = "多选";
                                    break;
                                case "input":
                                    title = "数字"
                                    break;
                            }
                            children.push({
                                menu:item.menu,
                                title
                            })
                        });
                    }
                    //备注
                    data = {
                        name:row.title,
                        spread:true,//是否展开
                        type:row.menu,
                        page:this.page,
                        current:index,
                        index1,
                        index2,
                        index3:undefined,//AddList组件内的组件索引
                        rules:{//统计内容方式
                           cal_type:"num", //统计类型:num=>列表数量,
                           isCal:false,//是否记分
                           score:0,//得分，以下条件外
                           condition:{
                               hasRemark,//有备注组件，分隔符分类判断
                               delimiter:"",//分隔符
                               mini:{
                                        data:0,
                                        hint:"",
                                        disabled:false,//是否生效
                                        score:0
                                },
                                max:{
                                        data:60,
                                        hint:"",
                                        disabled:false,//是否生效
                                        score:0
                                }
                           },
                        },
                        children:[]
                    }
                    break;
            }
            if(index1!=undefined&&data.type=="ListAdd"){
                //nav中的列表
                data.calList=row.setting.list
            }else if(data.type=="ListAdd"){
                data.calList=row.setting.list
            }
            console.log(data)
            console.log(data.itemlist)
            this.testList.push(data)
            this.$nextTick(() => {
                this.$refs.content.scrollTop = this.$refs.content.scrollHeight;
            })

        },
        //关闭或展开列表
        close(e){
            this.testList[e.index].spread = !this.testList[e.index].spread
        },
        //删除
        del(e){
            let index = e.index
            this.$confirm(`删除第${index+1}列?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.testList.splice(index,1)
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }).catch(() => {        
                });
        },
        next(){
            this.page++
        }
    }
}
</script>
<style lang="less" scoped>
.content{
    flex:1;
    overflow-y: scroll;
    position: relative;
    .line-box-item{
        border-top:0.5px rgb(222, 236, 243) solid;
    }
}
.test-box{
    display: flex;
    .left,.right{
        flex:1;
    }
    .title-nav{
        text-align: center;
        line-height: 40px;
        height: 40px;
        background: #5cb87a;
        color: #fff;
        
    }
    .item{
        opacity: 0.8;
        border:0.5px #dbdbdb solid;
        cursor: pointer;
        .slot-icon{
            margin-right:5px;
        }
    }
    .item:hover{
        border-color:#5cb87a;
        opacity:1;
    }
    .v-nav{
        border:1px #dbdbdb dashed;
        margin:5px;
    }
    .cal-item{
        position: relative;
        .el-tag{
            position:absolute;
            right:0px;
            z-index: 1;
        }
    }
}
</style>