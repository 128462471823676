<template>
    <div class="list-test" :class="hover?'hover':''" @click="click">
        <van-cell :title="item.title" :border="false"/>
        <div v-for="(item,index) in list" :key="index">
            <upload v-if="item.menu=='upload'" :item="item"/>
            <radio v-else-if="item.menu=='radio'||item.menu=='checkBox'" :item="item"/>
            <van-cell v-else-if="item.menu=='text'"
             :title="item.title" :value="item.explaination" />
             <number v-else-if="item.menu=='input'" :item="item"/>
        </div>
    </div>
</template>
<script>
import upload from '@/components/test/upload'
import radio from '@/components/test/radio'
import number from '@/components/test/number'
export default {
    name:"List",
    components:{
        upload,
        radio,
        number
    },
    props:{
        item:{
            type:Object
        },
        hover:{
            type:Boolean
        }
    },
    watch:{
        item:{
            handler(val){
                if(val.setting.list){
                    this.list = val.setting.list
                }
            },
            immediate:true,
            deep:true
        }
    },
    data(){
        return{
            list:[]
        }
    },
    methods:{
        click(){
            this.$emit("click")
        }
    }
}
</script>
<style lang="less" scoped>
.list-test{
    background: #e2e2e2;
    border:0.5px #b7eef5 dashed;
}
.hover{
    border:2px #fff solid;
}
.hover:hover{
    border:2px #39F solid;
}
</style>