<template>
    <div class="up-test" @click="click" :class="hover?'hover':''">
        <div class="up-title">{{item.title}}</div>
        <div class="upload-add">
            <i class="el-icon-plus" />
        </div>
    </div>
</template>
<script>
export default {
    name:"upload",
    props:{
        item:{
            type:Object
        },
        hover:{
            type:Boolean
        }
    },
    methods:{
        click(){
            this.$emit("click")
        }
    }
}
</script>
<style lang="less" scoped>
.up-test{
    background: #fff;
    padding:15px;
}
.upload-add{
    width:80px;
    height:80px;
    line-height: 80px;
    text-align: center;
    font-size: 2em;
    color: #999;
    border:0.5px #999 solid;
    background: #f8f8f8;
}
.up-title{
    text-align: left;
    font-size: 0.8em;
    color:#666;
    line-height: 35px;
}
.hover{
    border:2px #fff solid;
}
.hover:hover{
    border:2px #39F solid;
}
</style>