<template>
    <div class="cal-sign">
        <van-cell>
        <div slot="title">
            <div class="flex-v">
                <div class="flextitle">
                    {{data.name}}
                </div>
                <div class="r-num">
                    <el-tag effect="dark" type="danger" size="mini" 
                        closable @close="del(current)">第{{current+1}}个</el-tag>
                </div>
            </div>
        </div>
        <template #icon>
            <div style="vertical-align:middle;margin-right:5px">
                <van-icon name="../assets/images/yidong.png" @click="UpOrDown"/>
            </div>
        </template>
        <div slot="label">
            <van-field label="名称" v-if="data.spread" type="text"
        v-model="data.name" placeholder="请输入字段名称" />
        </div>
        <div class="cell-title" slot="right-icon">
            <van-icon @click="close(current)" :name="data.spread?'arrow-up':'arrow-down'" />
        </div>
        </van-cell>
        <van-radio-group v-model="data.rules.cal_type" v-if="data.spread" @change="radioChange(1)">
        <van-cell-group title="统计类型">
            <van-cell title="设备数据" clickable @click="data.rules.cal_type = 'deviceCheck'">
                <van-radio name="deviceCheck" slot="right-icon"/>
            </van-cell>
            <van-cell title="虫害数据" clickable @click="data.rules.cal_type = 'deviceData'">
                <van-radio name="deviceData" slot="right-icon"/>
            </van-cell>
        </van-cell-group>
        </van-radio-group>
        <!-- children自组件数据 -->
        <van-radio-group v-model="data.rules.children" v-if="data.rules.cal_type&&data.spread" @change="radioChange(0)">
        <van-cell-group title="选择统计设备类型">
            <van-cell :title="item.t_name" @click="data.rules.children=item.t_name" clickable v-for="(item,index) in deviceCheckData" :key="index">
                <van-radio  :name="item.t_name"  slot="right-icon"/>
                <div slot="label" v-if="data.rules.children==item.t_name">
                    <!-- 设备数量 -->
                    <div class="class-device-num">
                        <el-checkbox @change="checkboxChange(1,'getDeviceNum')" v-model="data.rules.getDeviceNum">{{item.t_name}}数量</el-checkbox>
                        <el-checkbox @change="checkboxChange(1,'getDeviceImgsNum')" v-model="data.rules.getDeviceImgsNum">{{item.t_name}}图片数量</el-checkbox>
                        <el-checkbox @change="checkboxChange(1,'getDeviceImgs')" v-model="data.rules.getDeviceImgs">{{item.t_name}}图片</el-checkbox>
                    </div>
                    <!-- 设备数据 -->
                    <div v-if="data.rules.cal_type == 'deviceCheck'&&!data.rules.getDeviceNum">
                        <div class="checkItem" v-for="it in item.checkList" :key="it">
                            <el-radio v-model="data.rules.childrenItem" :label="it">{{it}}</el-radio>
                        </div>
                    </div>
                    <div v-else-if="data.rules.cal_type == 'deviceData'&&!data.rules.getDeviceNum">
                        <div class="checkItem" v-for="it in item.numList" :key="it">
                            <el-radio v-model="data.rules.childrenItem" :label="it">{{it}}</el-radio>
                        </div>
                    </div>
                </div>
            </van-cell>
        </van-cell-group>
        </van-radio-group>
        <van-cell-group title="记分" v-if="data.rules.cal_type=='deviceData'&&data.spread||data.rules.cal_type=='deviceCheck'&&!data.rules.childrenItem&&data.spread" >
            <van-cell
                clickable
                title="开启记分？"
                >
                 <van-checkbox slot="right-icon" v-model="data.rules.isCal" :name="data.name" ref="checkboxes" />
                <van-field type="number"  label="记分" v-if="data.rules.isCal" 
                slot="label" v-model="data.rules.score" 
                 placeholder="以下条件外记分" />
            </van-cell>
            <van-cell title="少于多少条数"  v-if="data.rules.isCal">
                 <van-checkbox slot="right-icon" v-model="data.rules.condition.mini.disabled" 
                 ref="checkboxes1" />
                 <van-field type="number"  label="最少条数" v-if="data.rules.condition.mini.disabled" slot="label" v-model="data.rules.condition.mini.data" 
                 placeholder="最少*条（少于*条记分）" />
                 <van-field type="number" label="记分" v-if="data.rules.condition.mini.disabled" slot="label" v-model="data.rules.condition.mini.score" 
                 placeholder="少于以上条数得分" />
            </van-cell>
            <van-cell title="多于多少条数"  v-if="data.rules.isCal">
                 <van-checkbox slot="right-icon" v-model="data.rules.condition.max.disabled" 
                 ref="checkboxes2" />
                 <van-field type="number"  label="多于*条数" v-if="data.rules.condition.max.disabled" slot="label" v-model="data.rules.condition.max.data" 
                 placeholder="多于*条（多于*条记分）" />
                 <van-field type="number" label="记分" v-if="data.rules.condition.max.disabled" slot="label" v-model="data.rules.condition.max.score" 
                 placeholder="多于以上条数得分" />
            </van-cell>
        </van-cell-group>
        <!-- 上拉菜单 -->
        <van-dialog
        v-model="show"
        title=""
        >
            <van-picker :columns="columns" @change="onChange" />
        </van-dialog>
        <!-- 记分 -->
        <van-cell-group title="记分" v-if="data.rules.cal_type=='children'&&data.spread&&data.index2" >
            <van-cell
                clickable
                title="开启记分？"
                >
                 <van-checkbox slot="right-icon" v-model="data.rules.isCal" :name="data.name" ref="checkboxes" />
            </van-cell>
            <div v-if="data.rules.isCal">
                <van-cell
                clickable
                v-for="(item,index) in data.itemlist"
                :key="index"
                :title="item.name"
                >
                    <van-field type="number"  label="记分" 
                    slot="label" v-model="item.score" 
                    @change="changeSt"
                    placeholder="记分" />
                </van-cell>
            </div>
            
        </van-cell-group>
    </div>
</template>
<script>
import Vue from 'vue';
import {Checkbox,Cell,CellGroup,RadioGroup,Radio,Field,Picker,Dialog,Popover} from 'vant';
Vue.use(Checkbox).use(Cell).use(Popover).use(CellGroup).use(RadioGroup).use(Radio).use(Field).use(Picker).use(Dialog)
export default {
    name:"calDevice",
    props:{
        data:{
            type:Object
        },
        current:{
            type:Number
        }
    },
    data(){
        return{
            actions:[
            { text: '选项一' },
            { text: '选项二' },
            { text: '选项三' },
            ],
            showPopover:false,
            show:false,
            columns:[],
            deviceCheckData:[]
        }
    },
    watch:{
        data:{
            handler(){
                this.init()
                this.getDeviceType()
            },
            deep:true,
            immediate:true
        }
    },
    methods:{
        //多选
        checkboxChange(index,type){
            this.radioChange(index)
            if(type=='getDeviceNum'){
                //获取设备数量
                this.data.rules.getDeviceImgsNum = false
                this.data.rules.getDeviceImgs=false
            }else if(type=='getDeviceImgsNum'){
                //获取设备图片数量
                this.data.rules.getDeviceNum=false
                this.data.rules.getDeviceImgs=false
            }else if(type=="getDeviceImgs"){
                //获取设备图片
                this.data.rules.getDeviceNum=false
                this.data.rules.getDeviceImgsNum = false
                this.data.rules.isCal=false
            }
        },
        //选择上下移动
        UpOrDown(e){
           // console.log(e)
            this.$emit("upodown",{data:this.current,position:{x:e.clientX,y:e.clientY}})
        },
        radioChange(index){
            if(index){
                this.data.rules.childrenItem=''
            }
            this.$forceUpdate()
        },
        //获取设备类型
        getDeviceType(){
            let params={
                method:"devicetype"
            }
            this.$ajax.post(this.API.api,params).then(res=>{
                console.log(res.data)
                if(res.data){
                    res.data.forEach(item=>{
                        item.checkList=item.check_data?JSON.parse(item.check_data):[]
                        item.numList = item.num_data?JSON.parse(item.num_data):[]
                    })
                    this.deviceCheckData = res.data
                    console.log(this.deviceCheckData)
                }
            }).catch(err=>{
                //err
                this.loading=false 
                this.$message({
                    type: 'warning',
                    message: err.msg?err.msg:err
                }); 
                //console.log(err)
            })
        },
        delimiterChange(e){
            if(e){
                this.$message('请选择分割的子组件数据');
            }
        },
        changeSt(){
            this.$forceUpdate()
        },
        onChange(e,n){
            console.log(this.data.calList[n.value].menu)
            this.data.index3 = n.value
            if(this.data.calList[n.value]&&this.data.calList[n.value].menu=="checkBox"){
                //选择了自组件为选择项
                let list=[]
                this.data.calList[n.value].setting.list.forEach(item=>{
                    list.push({
                        name:item.name,
                        checked:false,
                        score:0
                    })
                })
                this.data.itemlist=list
            }else if(this.data.calList[n.value]&&this.data.calList[n.value].menu=="radio"){
                let list=[]
                this.data.calList[n.value].setting.list.forEach(item=>{
                    console.log(item)
                    list.push({
                        name:item,
                        checked:false,
                        score:0
                    })
                })
                this.data.itemlist=list
            }else{
                this.data.itemlist=[]
            }
            //console.log(this.data.itemlist)
        },
        init(){
            //this.getDeviceType()
            if(this.data.type=="ListAdd"){
                let columns=[]
                this.data.calList.forEach((e,index) => {
                    columns.push({text:e.title,value:index})
                });
                this.columns = columns
                
            }
        },
        //点击
        toggle(){
           this.data.rules.isCal = !this.data.rules.isCal
        },
        del(index){
            this.$emit("del",{index})
        },
        close(index){
            this.$emit("close",{index})
        }
    }
}
</script>
<style lang="less" scoped>
@import "../../assets/style/cal.less";
.class-device-num{
    border-bottom: 1px #f8f8f8 solid;
    background:#f8f8f8;
    padding:10px 0;
}
</style>