<template>
    <div class="cal-sign">
        <van-cell>
        <div slot="title">
            <div class="flex-v">
                <div class="flextitle">
                    {{data.name}}
                </div>
                <div class="r-num">
                    <el-tag effect="dark" type="danger" size="mini" 
                        closable @close="del(current)">第{{current+1}}个</el-tag>
                </div>
            </div>
        </div>
        <template #icon>
            <div style="vertical-align:middle;margin-right:5px">
                <van-icon name="../assets/images/yidong.png" @click="UpOrDown"/>
            </div>
        </template>
        <div slot="label">
            <van-field label="名称" v-if="data.spread" type="text"
        v-model="data.name" placeholder="请输入字段名称" />
        </div>
        <div class="cell-title" slot="right-icon">
            <van-icon @click="close(current)" :name="data.spread?'arrow-up':'arrow-down'" />
        </div>
        </van-cell>
        <van-radio-group v-model="data.rules.cal_type" v-if="data.spread">
        <van-cell-group title="统计类型">
            <van-cell title="服务用时" clickable @click="data.rules.cal_type = 'usetime'">
            <van-radio name="usetime" slot="right-icon"/>
            </van-cell>
            <van-cell title="开始时间" clickable @click="data.rules.cal_type  = 'start'">
            <van-radio name="start" slot="right-icon"/>
            </van-cell>
            <van-cell title="结束时间" clickable @click="data.rules.cal_type  = 'end'">
            <van-radio name="end" slot="right-icon"/>
            </van-cell>
            <van-cell title="签到地点" clickable @click="data.rules.cal_type  = 'start_address'">
            <van-radio name="start_address" slot="right-icon"/>
            </van-cell>
            <van-cell title="签退地点" clickable @click="data.rules.cal_type  = 'end_address'">
            <van-radio name="end_address" slot="right-icon"/>
            </van-cell>
        </van-cell-group>
        </van-radio-group>
        <van-cell-group title="记分" v-if="data.rules.cal_type=='usetime'&&data.spread" >
            <van-cell
                clickable
                title="开启记分？"
                >
                 <van-checkbox slot="right-icon" v-model="data.rules.isCal" :name="data.name" ref="checkboxes" />
                <van-field type="number"  label="记分" v-if="data.rules.isCal" 
                slot="label" v-model="data.rules.score" 
                 placeholder="以下条件外记分" />
            </van-cell>
            <van-cell title="少于多少服务时间(分钟)"  v-if="data.rules.isCal">
                 <van-checkbox slot="right-icon" v-model="data.rules.condition.mini.disabled" 
                 ref="checkboxes1" />
                 <van-field type="number"  label="最少用时" v-if="data.rules.condition.mini.disabled" slot="label" v-model="data.rules.condition.mini.data" 
                 placeholder="最少服务时间（少于*分钟记分）" />
                 <van-field type="number" label="记分" v-if="data.rules.condition.mini.disabled" slot="label" v-model="data.rules.condition.mini.score" 
                 placeholder="少于以上时间得分" />
            </van-cell>
            <van-cell title="多于多少服务时间（分钟）"  v-if="data.rules.isCal">
                 <van-checkbox slot="right-icon" v-model="data.rules.condition.max.disabled" 
                 ref="checkboxes1" />
                 <van-field type="number" label="最多用时" v-if="data.rules.condition.max.disabled" slot="label" v-model="data.rules.condition.max.data" 
                 placeholder="最多服务时间（多于*分钟记分）" />
                 <van-field type="number" label="记分" v-if="data.rules.condition.max.disabled" slot="label" v-model="data.rules.condition.max.score" 
                 placeholder="多于以上时间得分" />
            </van-cell>
        </van-cell-group>
    </div>
</template>
<script>
import Vue from 'vue';
import {Checkbox,Cell,CellGroup,RadioGroup,Radio,Field} from 'vant';
Vue.use(Checkbox).use(Cell).use(CellGroup).use(RadioGroup).use(Radio).use(Field)
export default {
    name:"calSign",
    props:{
        data:{
            type:Object
        },
        current:{
            type:Number
        }
    },
    methods:{
        //选择上下移动
        UpOrDown(e){
           // console.log(e)
            this.$emit("upodown",{data:this.current,position:{x:e.clientX,y:e.clientY}})
        },
        //点击
        toggle(){
           this.data.rules.isCal = !this.data.rules.isCal
        },
        del(index){
            this.$emit("del",{index})
        },
        close(index){
            this.$emit("close",{index})
        }
    }
}
</script>
<style lang="less" scoped>
@import "../../assets/style/cal.less";
</style>